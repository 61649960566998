import { IconButton, Stack, Tooltip } from "@mui/material";
import EditIcon from "src/assets/svg-icons/admin/edit.svg";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import AddPackagePopup from "../add-package-popup";

const TableActions = ({
  branchId,
  packageId,
  refetch,
}: {
  branchId: number;
  packageId: number;
  refetch: Function;
}) => {
  const { t } = useTranslation("general");
  const [openEditModal, setOpenEditModal] = useState(false);

  const handleOpenEditModal = () => setOpenEditModal(true);
  const handleCloseEditModal = () => setOpenEditModal(false);

  return (
    <Stack
      direction={"row"}
      spacing={"12px"}
      width={"100%"}
      justifyContent={"center"}
    >
      <Tooltip title={t("edit")} onClick={handleOpenEditModal}>
        <IconButton>
          <img src={EditIcon} alt="" />
        </IconButton>
      </Tooltip>

      {openEditModal && (
        <AddPackagePopup
          branchId={branchId}
          packageId={packageId}
          open={openEditModal}
          handleClose={handleCloseEditModal}
          refetch={refetch}
        />
      )}
    </Stack>
  );
};
export default TableActions;
