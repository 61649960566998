import { useEffect } from "react";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { useAddUserMutation, useUpdateUserMutation } from "src/api/users";
import { useGetRoles } from "src/api/roles";

const schema = yup.object().shape({
  mode: yup
    .string()
    .required("Required")
    .oneOf(["add", "edit"] as const),
  name: yup.string().required("Required"),
  email: yup.string().required("Required"),
  password: yup.string().when("mode", {
    is: "add",
    then: (value) => value.required("Required"),
  }),
  roles: yup
    .array()
    .of(yup.number().required("Required"))
    .required("Required")
    .min(1, "Required"),
});

const initForm: FormInputs = {
  mode: "add",
  name: "",
  email: "",
  password: "",
  roles: [],
};

interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  userData?: any;
  refetch: Function;
}

const AddEditUserPopup = (props: IProps) => {
  const { open, handleClose, refetch, userData } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("internalUsers");
  const { t: generalT } = useTranslation("general");

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: initForm,
  });

  // APIS
  const { mutate, isPending, status, error } = useAddUserMutation();
  const {
    mutate: mutateUpdate,
    isPending: isPendingUpdate,
    status: statusUpdate,
    error: errorUpdate,
  } = useUpdateUserMutation();

  const mode = getValues("mode");
  const { data: rolesData } = useGetRoles();
  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (mode === "add") {
      mutate(data);
    } else {
      mutateUpdate({
        id: userData.id,
        ...data,
      });
    }
  };
  useEffect(() => {
    if (userData) {
      reset({
        mode: "edit",
        name: userData.name,
        email: userData.email,
        roles: userData.roles.map((item: any) => item.id),
      });
    }
  }, [userData]);

  //Add user success and error handling
  useEffect(() => {
    if (status === "success") {
      reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  //Update user success and error handling
  useEffect(() => {
    if (statusUpdate === "success") {
      reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("updatedSuccessfully"),
        },
      ]);
    } else if (statusUpdate === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message:
            errorUpdate?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [statusUpdate]);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sx: "100%", lg: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {mode === "add" ? t("addNewUser") : t("editUser")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={2}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  label={t("userName")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.name}
                  helperText={errors.name?.message}
                />
              )}
            />
            {mode === "add" && (
              <>
                <Controller
                  name="email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={generalT("email")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.email}
                      helperText={errors.email?.message}
                    />
                  )}
                />
                <Controller
                  name="password"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      label={generalT("password")}
                      fullWidth
                      size="small"
                      {...field}
                      error={!!errors.password}
                      helperText={errors.password?.message}
                    />
                  )}
                />
              </>
            )}
            {/* Roles Multi Select */}
            <FormControl fullWidth size="small" error={!!errors.roles}>
              <InputLabel id="demo-multiple-checkbox-label">
                {t("roles")}
              </InputLabel>
              <Controller
                name={`roles`}
                control={control}
                defaultValue={[]}
                render={({ field }) => (
                  <Select
                    {...field}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    multiple
                    input={<OutlinedInput label={t("roles")} />}
                    renderValue={(selected) =>
                      selected
                        .map(
                          (selectedId) =>
                            rolesData?.data.find(
                              (role: any) => role.id === +selectedId
                            )?.name
                        )
                        .join(", ")
                    }
                  >
                    {rolesData?.data.map((role: any) => (
                      <MenuItem key={role.id} value={role.id}>
                        <Checkbox
                          checked={
                            field.value && field.value.indexOf(role.id) > -1
                          }
                        />
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.roles?.message}
              </FormHelperText>
            </FormControl>
          </Stack>
          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending || isPendingUpdate}
            >
              {mode === "add" ? t("addNewUser") : generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddEditUserPopup;
