import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, Chip, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import TableHeadText from "./table-head-text";
import TableActions from "./table-actions";

const BranchesTable = ({
  list,
  isLoading,
  refetch,
}: {
  list: any;
  isLoading: boolean;
  refetch: Function;
}) => {
  const { t } = useTranslation("customers");
  const { t: generalT } = useTranslation("general");
  const tableHeadText = [
    generalT("branchName"),
    generalT("vat"),
    generalT("branchLocation"),
    t("subscriptionStartDate"),
    t("subscriptionEndDate"),
    t("paid"),
    t("packageName"),
    generalT("activeInactive"),
    generalT("actions"),
  ];

  const emptyDataArr = list.length === 0;

  return (
    <Box>
      <Typography fontWeight={600} mb={1}>
        {t("theBranches")}
      </Typography>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  align="center"
                  sx={{ bgcolor: "#F9FAFB" }}
                >
                  <TableHeadText text={item} />
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={8} />
          ) : (
            <TableBody>
              {list?.map((row: any) => (
                <TableRow
                  key={row.id}
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell>{row.name}</TableCell>
                  <TableCell>%{row.vat}</TableCell>
                  <TableCell>{row.city.name}</TableCell>
                  <TableCell>{row.active_subscriptions?.start_date}</TableCell>
                  <TableCell>{row.active_subscriptions?.end_date}</TableCell>
                  <TableCell></TableCell>

                  <TableCell>
                    {row.active_subscriptions?.package?.name || "--"}
                  </TableCell>
                  <TableCell>
                    <Chip
                      label={generalT(row.status ? "active" : "inactive")}
                      color={row.status ? "success" : "error"}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <TableActions
                      branchId={row.id}
                      packageId={row.active_subscriptions?.package?.id}
                      refetch={refetch}
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
    </Box>
  );
};
export default BranchesTable;
