import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { TableLoadingSkeleton } from "src/shared/components/tables/table-loading-skeleton";
import { NoData } from "src/shared/components/tables/no-data";
import { Box, TablePagination } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import TableActions from "./table-actions";
import { useGetRoles } from "src/api/roles";

const RolesTable = () => {
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [total, setTotal] = useState<number>(0);
  const { t } = useTranslation("rolesManagement");

  const tableHeadText = [
    t("role"),
    t("createdBy"),
    t("createdAt"),
    t("actions"),
  ];

  // APIS
  const { data, isLoading, refetch } = useGetRoles({ page: pageNumber + 1 });

  const emptyDataArr = data?.data.length === 0;

  const handleChangePage = (event: unknown, newPage: number) => {
    setPageNumber(newPage);
  };

  useEffect(() => {
    if (data) {
      setTotal(data.meta?.total as number);
    }
  }, [data]);

  return (
    <Box>
      <TableContainer component={Paper} variant="outlined">
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              {tableHeadText.map((item) => (
                <TableCell
                  key={item}
                  align="center"
                  sx={{
                    fontSize: "12px",
                    fontWeight: 500,
                    lineHeight: "18px",
                    color: "#475467",
                    bgcolor: "#F9FAFB",
                  }}
                >
                  {item}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {isLoading ? (
            <TableLoadingSkeleton rowsLength={8} cellsLength={4} />
          ) : (
            <TableBody>
              {data.data.map((role: any) => {
                return (
                  <TableRow
                    key={role.id}
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell align="center">{role.name}</TableCell>
                    <TableCell align="center">
                      {role.created_by?.name}
                    </TableCell>
                    <TableCell align="center">{role.created_at}</TableCell>
                    <TableCell align="center">
                      <TableActions
                        rowId={role.id}
                        rowName={role.name}
                        refetch={refetch}
                      />
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          )}
        </Table>
        {emptyDataArr && <NoData />}
      </TableContainer>
      <TablePagination
        sx={{ mt: 1 }}
        rowsPerPageOptions={[20]}
        component="div"
        count={total}
        rowsPerPage={20}
        page={pageNumber}
        onPageChange={handleChangePage}
        showFirstButton
        showLastButton
      />
    </Box>
  );
};
export default RolesTable;
