import { useEffect } from "react";
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { LoadingButton } from "@mui/lab";
import { useRecoilState } from "recoil";
import { notificationsState } from "src/store/notifications";
import { useTranslation } from "react-i18next";
import { useGetPackages } from "src/api/cms";
import { useAdPackageMutation } from "src/api/customers";

const schema = yup.object().shape({
  package: yup.string().required("Required"),
  duration: yup.string().required("Required"),
});

const initForm: FormInputs = {
  package: "",
  duration: "",
};

interface FormInputs extends yup.InferType<typeof schema> {}

interface IProps {
  open: boolean;
  handleClose: () => void;
  branchId: number;
  packageId: number;
  refetch: Function;
}

const AddPackagePopup = (props: IProps) => {
  const { open, handleClose, refetch, packageId, branchId } = props;
  const [notifications, setNotifications] = useRecoilState(notificationsState);
  const { t } = useTranslation("internalUsers");
  const { t: generalT } = useTranslation("general");

  //React hook form
  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormInputs>({
    mode: "onBlur",
    resolver: yupResolver(schema),
    defaultValues: initForm,
  });

  // APIS
  const { mutate, isPending, status, error } = useAdPackageMutation();
  const { data: rolesData } = useGetPackages();

  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    mutate({
      branch_id: branchId,
      package_id: +data.package,
      duration: +data.duration,
    });
  };

  //Add success and error handling
  useEffect(() => {
    if (status === "success") {
      reset(initForm);
      handleClose();
      refetch();
      setNotifications([
        ...notifications,
        {
          type: "success",
          message: generalT("addedSuccessfully"),
        },
      ]);
    } else if (status === "error") {
      setNotifications([
        ...notifications,
        {
          type: "error",
          message: error?.data.errors[0].value || generalT("somethingWrong"),
        },
      ]);
    }
  }, [status]);

  console.log({ errors });

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Stack
        spacing={3}
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: { sx: "100%", lg: "537px" },
          bgcolor: "#FFF",
          border: "1px solid #D0D5DD",
          borderRadius: 1,
          boxShadow: 24,
          p: 3,
        }}
      >
        <Box
          display={"flex"}
          justifyContent={"space-between"}
          alignItems={"center"}
          p={0}
        >
          <Typography color={"#101828"} fontSize={"20px"} lineHeight={"24px"}>
            {t("package")}
          </Typography>
          <IconButton onClick={handleClose} sx={{ p: 0 }}>
            <CloseIcon />
          </IconButton>
        </Box>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Stack spacing={1.5}>
            {/* Package Select */}
            <FormControl fullWidth size="small" error={!!errors.package}>
              <InputLabel id="demo-simple-select-label">
                {t("package")}
              </InputLabel>
              <Controller
                name={"package"}
                control={control}
                render={({ field }) => (
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    {...field}
                    label={t("package")}
                  >
                    {rolesData?.data.map((role: any) => (
                      <MenuItem key={role.id} value={role.id}>
                        {role.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              <FormHelperText id="my-helper-text">
                {errors.package?.message}
              </FormHelperText>
            </FormControl>
            <Controller
              name="duration"
              control={control}
              render={({ field }) => (
                <TextField
                  type="number"
                  label={t("duration")}
                  fullWidth
                  size="small"
                  {...field}
                  error={!!errors.duration}
                  helperText={errors.duration?.message}
                />
              )}
            />
          </Stack>

          <Stack spacing={2} direction={"row"} mt={3}>
            <Button
              variant="outlined"
              color="tertiary"
              fullWidth
              onClick={handleClose}
            >
              {generalT("cancel")}
            </Button>
            <LoadingButton
              variant="contained"
              fullWidth
              type="submit"
              loading={isPending}
            >
              {generalT("save")}
            </LoadingButton>
          </Stack>
        </form>
      </Stack>
    </Modal>
  );
};

export default AddPackagePopup;
