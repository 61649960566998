import { Box, CircularProgress, Grid, Stack, Typography } from "@mui/material";
import SettingIcon from "src/assets/svg-icons/side-bar/settings.svg";
import RightArrow from "src/assets/svg-icons/admin/right-arrow.svg";
import InfoItem from "./info-item";
import { useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import HistoryTable from "./table";
import { useGeCustomerById } from "src/api/customers";
import BranchesTable from "./branches-table";

const CostumerDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const { t } = useTranslation("customers");
  const { t: generalT } = useTranslation("general");
  const { data, isLoading, refetch } = useGeCustomerById({
    id: id ?? "",
    enabled: true,
  });

  const navigateToJazarCustomer = () => {
    navigate("/customers");
  };

  return (
    <Stack gap={3} p={{ xs: 2, sm: 4 }}>
      <Stack
        direction={"row"}
        spacing={"12px"}
        alignItems={"center"}
        color={"#101828"}
      >
        <Box
          display={"flex"}
          justifyContent={"center"}
          alignItems={"center"}
          width={"60px"}
          height={"60px"}
          bgcolor={"#D0D5DD"}
          borderRadius={"16px"}
        >
          <img src={SettingIcon} width={"32px"} alt="" />
        </Box>
        <Typography fontSize={"32px"} lineHeight={"38.4px"}>
          {t("jazarCustomers")}
        </Typography>
      </Stack>
      {/* divider */}
      <Box bgcolor={"#F2F4F7"} width={"100%"} height={"1px"} />

      <Stack spacing={2} direction={"row"} alignItems={"center"}>
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          onClick={navigateToJazarCustomer}
          sx={{ cursor: "pointer" }}
        >
          {t("adminSystem")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Typography
          color={"#475467"}
          lineHeight={"19.2px"}
          sx={{ cursor: "pointer" }}
          onClick={navigateToJazarCustomer}
        >
          {t("jazrCostumer")}
        </Typography>
        <img src={RightArrow} alt="" />
        <Box borderRadius={"4px"} bgcolor={"#EAECF0"} p={"4px 8px"}>
          <Typography color={"#344054"} fontWeight={600} lineHeight={"19.2px"}>
            ID-191919
          </Typography>
        </Box>
      </Stack>
      {isLoading && !data ? (
        <Box display={"flex"} justifyContent={"center"} pt={20}>
          <CircularProgress />
        </Box>
      ) : (
        <>
          <Stack bgcolor={"#F9FAFB"} p={2}>
            <Grid container spacing={2}>
              <Grid item md={6}>
                <InfoItem
                  label={t("companyName")}
                  value={data.data.company.name}
                />
              </Grid>
              <Grid item md={6}>
                <InfoItem
                  label={t("joinUsAt")}
                  value={data.data.company.name}
                />
              </Grid>
              <Grid item md={6}>
                <InfoItem label={t("phone#")} value={data.data.phone} />
              </Grid>
              <Grid item md={6}>
                <InfoItem
                  label={t("status")}
                  value={
                    data.data.status ? generalT("active") : generalT("inActive")
                  }
                />
              </Grid>
              <Grid item md={6}>
                <InfoItem label={t("email")} value={data.data.email} />
              </Grid>
              <Grid item md={6}>
                <InfoItem
                  label={t("lastLogIn")}
                  value={data.data.last_login || ""}
                />
              </Grid>
              <Grid item md={6}>
                <InfoItem
                  label={t("# Branches")}
                  value={data.data.company.branches?.length}
                />
              </Grid>
              <Grid item md={6}>
                <InfoItem label={t("packageName")} value={""} />
              </Grid>
              <Grid item md={6}>
                <InfoItem
                  label={t("lastEditBy")}
                  value={data.data.last_edit_by?.name}
                />
              </Grid>
              <Grid item md={6}>
                <InfoItem
                  label={t("lastEditDate")}
                  value={data.data.last_edit_at}
                />
              </Grid>
            </Grid>
          </Stack>
          <BranchesTable
            list={data?.data.company.branches}
            isLoading={isLoading}
            refetch={refetch}
          />
          <HistoryTable list={data?.data.history} isLoading={isLoading} />
        </>
      )}
    </Stack>
  );
};

export default CostumerDetails;
